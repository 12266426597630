import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// Vitrnx generic components
import Blog from '../../containers/blog/Blog'
import CallbackPage from '../../components/navigation/Callback'
import PostList from '../../containers/blog/PostList'
import Users from '../../containers/users/Users'

// Upala specific 
import Layout from './layout/Layout'
import SinglePage from './pages/SinglePage'

// Private
import Dashboard from '../../containers/dashboard/Dashboard'
import Register from '../../containers/auth/Register'
import Login from '../../containers/auth/Login'
import Logout from '../../containers/auth/Logout'

class UpalaRoutes extends Component {

  render() {
    const { isAuth, userRoles } = this.props

    // Default routes 
    let routes = [
      <Route key="callback" path="/callback" component={CallbackPage} />,
      <Route key="static" path="/s" component={SinglePage} />,
      <Route key="thoughts" path="/p" component={Blog} />,
      <Route key="news" path="/q" component={Blog} />,
      <Route key="books" path="/r" component={Blog} />,
      <Route key="home" path="/" exact component={SinglePage}/>, 
    ];

    // Login management
    if (isAuth) {
      routes = [...routes, (<Route key="logout" path="/logout" component={Logout} />)];
    } else {
      routes = [
          ...routes, 
          (<Route key="register" path="/register" component={Register} />), 
          (<Route key="login" path="/login" component={Login} />)
      ];
    }

    // Private routes
    if (userRoles && (userRoles.includes("ADMIN") || userRoles.includes("EDITOR"))) {
      routes = [...routes, (<Route key="list-all" path="/all" render={(props) => <PostList {...props} listType="list" />} />)];
    }
    if (userRoles && (userRoles.includes("ADMIN") || userRoles.includes("USER_ADMIN"))) {
      routes = [
        ...routes,
        (<Route key="admin-dashboard" path="/dashboard" component={Dashboard} />),
        (<Route key="list-users" path="/u/" component={Users} />)
      ]
    }

    routes = [...routes, (<Redirect key="default-redirect" to="/" />)]

    return (
      <Layout>
        <Switch>
          {/* Spread operator does not work here, WHY??  */}
          {routes.map(element => element)}
        </Switch>
      </Layout >
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.oidc.user != null,
    userRoles: state.auth.userRoles,
  };
};

export default connect(mapStateToProps)(withRouter(UpalaRoutes));
