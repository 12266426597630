
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Chip } from '@material-ui/core';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    padding: theme.spacing(1) / 2,
  },
  chip: {
    margin: theme.spacing(1) / 3,
  },
});

class ChipArray extends React.Component {

  handleDelete = data => () => {
    this.props.onDelete(data);  
  }

  render() {
    const { classes, chipValues} = this.props;
    return (
      <div className={classes.root}>
        {chipValues.map(data => {
          return (
            <Chip
              key={data.key}
              label={data.label}
              onDelete={this.handleDelete(data.key)}
              className={classes.chip}
            />
          );
        })}
      </div>
    );
  }
}

ChipArray.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChipArray);

