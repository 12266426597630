import { reducer as oidcReducer } from 'redux-oidc';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'

import audioReducer from './audio';
import authReducer from './auth';
import postsReducer from './posts';
import postReducer from './post';
import uiReducer from './ui';

// Add your own reducers here.
const rootReducer = combineReducers({
  oidc: oidcReducer,
  auth: authReducer,
  posts: postsReducer,
  post: postReducer,
  ui: uiReducer,
  form: formReducer,
  audio: audioReducer,
});

export default rootReducer;
