import React from 'react';

import classes from './nav.module.css'

import {NavLink} from 'react-router-dom';

const navigationItem = (props) => (
    <li className={classes.NavigationItem}>
        <NavLink style={{textAlign: 'left'}} className={classes.NavigationItem} to={props.link} onClick={props.onClick}>
           {props.children}
        </NavLink>
    </li>
);

export default navigationItem;
