import apiServer, { publicServer } from '../../utils/backend';
// import apiServer, { publicServer } from '../../utils/backend';

import { POST_CREATE, POST_READ, POST_UPDATE, POST_START_SAVING, POST_SAVE_SUCCESS, POST_SAVE_FAILURE } from './actionTypes';

export const createPost = () => {
    return {
        type: POST_CREATE,
    }
};

export const editPost = (slug) => {
    let uri = '/posts/' + slug;

    return (dispatch, getState) => {
        let currState = getState()
        var options = { headers: { 'Authorization': currState.oidc.user.id_token } };
        apiServer.get(uri, options)
            .then(response => {
                console.log('Fetch post success:', response.data)
                dispatch(postRead(response.data.post));
            }).catch(error => {
                console.log('could not retrieve post at ', slug, ', cause:', error)
            });
    }
};


export const postRead = (post, openForEdit) => {
    return {
        type: POST_READ,
        post: post,
        openForEdit: openForEdit,
    }
}

export const getPost = (slug, openForEdit) => {
    let uri = '/posts/' + slug;

    return (dispatch, getState) => {
        if (openForEdit) {
            let currState = getState()
            if (!currState.oidc.user){
                console.log('could not retrieve post for edit, no session found.')
                // TODO manage error
                return //  No auth info: avoid NPE 
            }
            var options = { headers: { 'Authorization': currState.oidc.user.id_token } };
            apiServer.get(uri, options)
                .then(response => {
                    dispatch(postRead(response.data.post, true));
                }).catch(error => {
                    console.log('could not retrieve post at', slug, ', cause:', error)
                });
        } else
            publicServer.get(uri)
                .then(response => {
                    dispatch(postRead(response.data.post, false));
                }).catch(error => {
                    console.log('could not retrieve post at ', slug, ', cause:', error)
                });
    }
};

export const savePost = (post) => {
    return (dispatch, getState) => {

        dispatch((mypost) => {
            return {
                type: POST_UPDATE,
                post: mypost,
            }
        });

        dispatch({ type: POST_START_SAVING });

        let currState = getState()
        var options = { headers: { 'Authorization': currState.oidc.user.id_token } };

        let uri = '/posts';
        if (!currState.post.isNew) {
            uri += '/' + currState.post.post.slug
        }

        apiServer.post(uri, post, options).then(response => {
            console.log(response); // TODO Give feedback to the user, TODO also implement regular auto-save 
            dispatch((mypost) => {
                return {
                    type: POST_SAVE_SUCCESS,
                    post: mypost,
                }
            });
        }).catch(error => {
            console.log(error); // TODO Give feedback to the user, TODO also implement regular auto-save 
            dispatch((error) => {
                return {
                    type: POST_SAVE_FAILURE,
                    error: error,
                }
            });
        });
    };
};


export const deletePost = slug => {
    return (dispatch, getState) => {

        let currState = getState()
        console.log('About to delete, token:', currState.oidc.user.id_token)
        
        var options = { headers: { 'Authorization': currState.oidc.user.id_token } };
        let uri = '/posts/' + slug;

        return apiServer.delete(uri, options)
            .then(response => {
                console.log('Deleted post at ', slug)
                // dispatch(deletePostSuccess(response.data));
            })
            .catch(error => {
                console.log('could not delete post at ', slug, ', cause:', error)
                // dispatch(deletePostFailure(error));
            });
    };
};


// export const discardPostChanges = () => {
//     return {
//         type: POST_CREATE,
//     }
// };

// export const putPost = ({ post }) => {
//     return (dispatch) => {
//         return apiServer.post(`${apiUrl}/add`, { title, body })
//             .then(response => {
//                 dispatch(createPostSuccess(response.data))
//             })
//             .catch(error => {
//                 throw (error);
//             });
//     };
// };

// export const putPostSuccess = (data) => {
//     return {
//         type: ADD_POST,
//         payload: {
//             _id: data._id,
//             title: data.title,
//             body: data.body
//         }
//     }
// };

// export const putPostError = (data) => {
//     return {
//         type: ADD_POST,
//         payload: {
//             _id: data._id,
//             title: data.title,
//             body: data.body
//         }
//     }
// };


// export const deletePostSuccess = id => {
//     return {
//         type: DELETE_POST,
//         payload: {
//             id
//         }
//     }
// }




