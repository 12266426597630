import { createUserManager } from 'redux-oidc';
import { Log } from 'oidc-client';

import getConfig from './config'

Log.logger = console;
Log.level = Log.DEBUG;

const userManager = createUserManager(getConfig());

export default userManager;
