import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { publicServer } from '../../../utils/backend';

import BookCard from '../../../components/blog/BookCard';
import AuxWrapper from '../../../hoc/AuxWrapper/AuxWrapper';

import pages from './DefaultContent';

class OnePage extends Component {

    state = {
        loadedPost: null,
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        this.loadData();
    }

    loadData() {
        if (!this.state.loadedPost) {
            let path = '/posts/' + this.props.postId; // page-d-accueil'
            publicServer.get(path).then(response => {
                let currPost = response.data.post ?
                    response.data.post : pages[this.props.postId];
                this.setState({ loadedPost: currPost });
            });
        }
    }

    render() {
        let post = this.state.loadedPost;
        if (!post) return null;        
        return (<BookCard title={post.title} body={post.body} hero={post.hero} even={true} />);
    }
}

class DefaultPage extends Component {
    render() {
        return (
            <AuxWrapper>
                <Route path="/s/page-d-accueil" exact render={() => (<OnePage postId="page-d-accueil" />)} />
                <Route path="/s/mentions-legales" exact render={() => (<OnePage postId="mentions-legales" />)} />
                <Route path="/s/atelier" exact render={() => (<OnePage postId="atelier" />)} />
                <Route path="/s/entretien" exact render={() => (<OnePage postId="entretien" />)} />
                <Route path="/s/contact" exact render={() => (<OnePage postId="contact" />)} />
                <Route path="/" exact render={() => (<OnePage postId="page-d-accueil" />)} />
            </AuxWrapper>
        );
    }
}

export default DefaultPage;
