import React from 'react';
import PropTypes from 'prop-types';
import ChipArray from './Chips'

import { FormControl, MenuItem, Select } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
    },
    chip: {
    },
});



// FIXME should not be defined here
const knownTags = [
    { key: "actualites", label: "Actualités" },
    { key: "reflexions", label: "Réflexions" },
    { key: "livres", label: "Livres" }
];


class TagSelect extends React.Component {

    state = {}

    static getDerivedStateFromProps(props, state) {
        // console.log('in TagSelect.getDerivedState:', props, state)

        if (props.meta.initial && props.meta.pristine) {
            return { tags: props.meta.initial };
        }
        return null;
    }

    handleTagRemoved = key => {
        let tags = this.state.tags;
        let currTags = tags ? tags.split(' ') : [];
        if (!currTags.includes(key)) return; // should never happen
        currTags.splice(currTags.indexOf(key), 1);
        // this.props.onUpdate("tags", currTags.join(' '))
        this.props.input.onChange(currTags.join(' '))
        this.setState({ tags: currTags.join(' ') })
    }

    handleSelectChange = event => {
        let key = event.target.value;
        if (key === "") return; // None selected
        let tags = this.state.tags;
        let currTags = tags ? tags.split(' ') : [];
        if (currTags.includes(key)) return; // should never happen
        currTags = [...currTags, key]
        // onUpdate={field}
        this.props.input.onChange(currTags.join(' '))
        this.setState({ tags: currTags.join(' ') })
    }

    getSuggestions = () => {
        let tags = this.state.tags;
        let currTags = tags ? tags.split(' ') : [];
        return knownTags.map(
            item => (
                currTags.includes(item.key) ? null :
                    <MenuItem key={item.key} value={item.key}>{item.label}</MenuItem>
            )
        );
    }

    getLabelFromKey(key) {
        let res = knownTags.find(obj => {
            return obj.key === key
        })
        return res ? res.label : key;
    }

    render() {
        let tags = this.state.tags;
        let currTags = tags ? tags.split(' ') : [];
        let currTagsWithLabel = currTags.map(tag => ({ key: tag, label: this.getLabelFromKey(tag) }))

        return (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ChipArray chipValues={currTagsWithLabel} onDelete={this.handleTagRemoved} />
                <FormControl >
                    <div style={{ width: '150px', paddingTop: '8px', }}>
                        <Select onChange={this.handleSelectChange} value="" style={{ width: '150px' }} autosize="false">
                            <MenuItem value="">
                                <em>Choose a tag</em>
                            </MenuItem>
                            {this.getSuggestions()}
                        </Select>
                    </div>
                    {/* <FormHelperText>Tags: News, FAQ...</FormHelperText> */}
                </FormControl>
            </div>
        );
    }
}

TagSelect.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TagSelect);




