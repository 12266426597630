import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import PostList from './PostList';
import Post from '../../components/blog/Post';
import PostEditor from './PostEditor';

import { createPost, editPost } from '../../store/actions/post'


// The Blog component manages routes and explicitely forwards the JWT token retrieved from the Redux store
class Blog extends Component {

    render() {
        return (
            <Switch>

                {/* New Post */}
                <Route path="/p/edit/" exact render={
                    (props) => (<PostEditor {...props} token={this.props.token} userId={this.props.userId} userRoles={this.props.userRoles} />)
                } />

                {/* Edit Post */}
                <Route path="/p/edit/:id" render={
                    (props) => ( <PostEditor  {...props} token={this.props.token} userId={this.props.userId} userRoles={this.props.userRoles} /> )
                } />

                {/* Display Post */}
                <Route path={'/p/:id'} exact render={
                    (props) => (
                        <Post  {...props}
                            token={this.props.token}
                            userId={this.props.userId}
                            userRoles={this.props.userRoles}
                        />
                    )
                } />

                {/* Filter by Tag */}
                <Route path={'/q/:id'} exact render={(props) => (<PostList {...props} listType="card" />)} />

                {/* Specific category with adapted layout for books */}
                <Route path={'/r/:id'} exact render={(props) => (<PostList {...props} listType="book" />)} />

            </Switch>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.oidc.user ? state.oidc.user.id_token : null,
        userId: state.auth.userId,
        userRoles: state.auth.userRoles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreatePost: () => dispatch(createPost()),
        onEditPost: (slug) => dispatch(editPost(slug))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
