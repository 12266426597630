
// Define colors
const highlightColor = '#124d12'
const textColor = '#030F03'
const bgColor = '#ffffff'

// Dégradés
const topRightColor = "#8bc34a";
const bottomLeftColor = "#dcedc8";


const urgentAndImportantColor = "#F44336";
const urgentColor = "#1A237E";
const importantColor = "#FF8F00";
const normalColor = "#2E7D32";


export {
    highlightColor,
    textColor,
    bgColor,

    topRightColor,
    bottomLeftColor,

    urgentAndImportantColor,
    urgentColor,
    importantColor,
    normalColor,
};

