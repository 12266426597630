import React from 'react';

import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

import { withStyles, Card, CardContent, CardMedia, Typography } from '@material-ui/core';

import customCss from './blog.module.css';

const styles = () => ({

  card: {
    //margin: '1em auto',
    margin: '0.2em',
    display: 'flex',
    background: 'transparent',
    boxShadow: 'none',
    ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key
      flexDirection: 'column',
    }
  },
  content: {
    flex: 'grow',
    width: '67%'
  },
  title: {
    ['@media (max-width:599px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '1.4em',
      margin: '0em 0em 0em 0em',
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  cover: {
    width: '33%',
    margin: '1em',
  },
});

const BookCard = (props) => {
  const { classes, even } = props;
  const content = (<CardContent className={classes.content}>
    <Typography variant="h4" className={classes.title}>
      {props.title}
    </Typography>
    <Typography style={{fontSize: '1.2em'}} className={customCss.Desc}>
      {props.desc}
    </Typography>
    <Markdown escapeHtml={true} source={props.body} />
  </CardContent>);

  const media = props.hero ? (<CardMedia className={classes.cover} image={"../files/images/" + props.hero} title={props.title} />) : null;

  return (<Card className={classes.card}>
    {even ? media : content}
    {even ? content : media}
  </Card>);
}

BookCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookCard);