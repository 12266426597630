import React from 'react';
import moment from 'moment';

import { withStyles, Avatar, ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';


const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

const PostListItem = (props) => {

  const { classes, post, postSelected } = props

  // console.log("List item", post)
  let ddate = post.publishedAt ? post.publishedAt : post.updatedAt;
  // console.log("List item, displayed date:", ddate)

  let dateStr = moment(ddate, 'YYYY-MM-DDThh:mm:ssZ', false).format('DD/MM/YYYY');

  // let otherDateStr = moment(post.updatedAt, 'YYYY-MM-DDThh:mm:ssZ', false).format('YYYY-MM-DD HH:mm');

  //         primary={post.title + ', by ' + post.author + ' on ' + dateStr + ', ' + post.tags}
  //         primary={post.title + ', le ' + dateStr + ' (last update: '+otherDateStr+ '), ' + post.tags}

  return (
    <ListItem onClick={postSelected}>
      <ListItemAvatar>
        <Avatar className={classes.avatar} src={"../files/images/" + post.thumb} />
      </ListItemAvatar>
      <ListItemText
        style={{ textAlign: 'left' }}
        primary={post.title + ', le ' + dateStr + ', ' + post.tags}
        secondary={post.desc}
      />
    </ListItem>
  );
}

export default withStyles(styles)(PostListItem);