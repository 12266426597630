import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// **Hack here to switch to specific routes.**
import MyRoutes from './specific/upala/Routes'

// Provides default material UI css props to children components
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { lightGreen } from '@material-ui/core/colors';
import { transition } from "./assets/jss/common.jsx";

import { highlightColor, textColor } from "./constants"

// Global theme using Material UI
const theme = createMuiTheme({

  root: {
    color: textColor
    // overflow: 'hidden',
  },

  palette: {
    primary: lightGreen,
    secondary: { main: highlightColor },
  },

  typography: {
    color: textColor,
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Fira Sans"', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
    fontWeightMedium: 400,

    h2: {
      fontWeight: 400,
    },
    body1: {
      fontWeight: 350,
      color: textColor
    },
    body2: {
      fontWeight: 400,
      color: textColor
    },
    subtitle1: {
      color: textColor
    },
  },

  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },

  mainPanel: {
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: 'touch'
  },
});

class App extends Component {

  render() {
    // const { isAuth, userRoles } = this.props

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <MyRoutes props={this.props} />
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;

// const mapStateToProps = state => {
//   return {
//     isAuth: state.oidc.user != null,
//     userRoles: null,
//     dname: state.oidc.user !== null ? state.oidc.user.profile.name : "Anonymous",
//   };
// };

// // const mapDispatchToProps = dispatch => {
// //   return {
// //     onTryAutoSignup: () => dispatch(actions.authCheckState()),
// //   };
// // };

// export default connect(mapStateToProps)(App);
