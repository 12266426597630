import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from './Header'
import AppBar from './AppBar'
import Footer from './Footer'

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import * as actions from '../../../store/actions/index';

import { bgColor } from '../../../constants'

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        overflowX: 'visible',
        backgroundColor: bgColor,
        width: '100%',
        /* width: 80%; */
        // margin: 'auto',
    },
    header: {
    },
    appBar: {
    },
    content: {
        flexGrow: 1,
        position: 'relative',
        display: 'flex-start',
        padding: '0em 0.2em 0em .2em',
        // margin: 'auto',
        ['@media (max-width:949px)']: { // eslint-disable-line no-useless-computed-key
            padding: '0em 0em 0em 0em',
        }
    },
    footer: {
    }
};

class Layout extends Component {

    render() {
        const { isAuth, userRoles, classes, className } = this.props
        return (
            <div className={classNames(classes.root, className)}>
                <Header className={classes.Header}
                    isAuth={isAuth}
                    userRoles={userRoles}
                    menuItems={this.getMenuItems()}
                    privItems={this.getPrivItems()}
                />
                <AppBar className={classes.AppBar}
                    isAuth={isAuth}
                    userRoles={userRoles}
                    menuItems={this.getMenuItems()}
                    privItems={this.getPrivItems()}
                    onToggleLogin={() => this.toggleLogin()}
                />
                <div className={classNames(classes.content)}>
                    {this.props.children}
                </div>
                <Footer
                    className={classes.Footer}
                    isAuth={isAuth}
                />
            </div>
        );
    }

    getMenuItems() {
        let items = [
            { url: '/', label: 'Accueil' },
            { url: '/q/reflexions', label: 'Réflexions' },
            { url: '/q/actualites', label: 'Actualités' },
            { url: '/r/livres', label: 'Livres' },
            { url: '/s/entretien', label: 'Entretien individuel' },
            { url: '/s/atelier', label: 'Travail en atelier' },
            { url: '/s/contact', label: 'Contact' },
        ]
        // Legacy routes...
        // if (this.props.userRoles && (this.props.userRoles.includes("VOLUNTEER") || this.props.userRoles.includes("ORGANISATION"))) {
        // items = [ ...items, { url: '/dashboard', label: 'Dashboard' },{ url: '/logout', label: 'Ciao!' }]; }
        return items;
    }

    getPrivItems() {
        let items = []

        if (this.props.isAuth) {
            if (this.props.userRoles && this.props.userRoles.includes("ADMIN")) {
                items = [
                    { url: '/all', label: 'Tous les articles' },

                    // We do not handle user anymore... 
                    // TODO: reimplement 
                    // { url: '/u', label: 'Utilisateurs' },
                ]
            }
        }
        return items;
    }

    toggleLogin = () => {
        if (this.props.isAuth) {
            this.props.onLogout();
        } else {
            this.props.onLogin();
        }
    };
}

const mapStateToProps = state => {
    return {
        isAuth: state.oidc.user != null,
        userRoles: state.auth.userRoles,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        onLogin: () => dispatch(actions.login()),
        onLogout: () => dispatch(actions.logout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Layout));
