import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm} from 'redux-form'

import { getPost, createPost, savePost, deletePost } from '../../store/actions/post'

import apiServer from '../../utils/backend';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';

import { Button } from '@material-ui/core';

import TagSelect from '../../components/ui/Select/TagSelect'


// Inline styles for the table 

const table = { width: '100%', padding: '0.2em', background: 'inherit', marginTop: '2em' };
const tr = { width: '100%', padding: '1em 0em' };
const label = { textAlign: 'right', width: '8em', border: '0px solid' };
const input = { width: '100%', border: '0px solid' };
const trs = { backgroundColor: 'transparent', }
const btn = { width: '8em', padding: '.4em 0.6em', margin: '0em 1em' }

class PostForm extends React.Component {

    state = {
        isNew: true,
        needsRefresh: false,
    }

    reloadIfNecessary = () => {
        if(this.state.needsRefresh){
            // console.log('Launching post retrieval')
            this.state.prevId === "" ? this.props.onCreate() : this.props.onOpen(this.state.prevId);    
        }
    }

    componentDidMount() {
        this.reloadIfNecessary()
    }

    componentDidUpdate(prevProps, prevState) {
        this.reloadIfNecessary()
    }

    static getDerivedStateFromProps(props, state) {
        let currId = ""
        if (props.match.params.id) {
            currId = props.match.params.id
        }

        // special case for the first time we pass here, 
        // to simplify the creation case where prev id is empty
        if (state.isNew || currId !== state.prevId) {
            return {
                needsRefresh: true,
                isNew: false,
                prevId: currId,
            }
        }

        // No state update necessary
        return null;
    }

    deleteHandler = () => {
        if (window.confirm('Are you sure you want to completely remove this post?')) {
            if (this.props.initialValues.id && this.props.initialValues.id !== "") {
                this.props.onDelete(this.props.initialValues.slug)
                this.props.history.push("/");
            } else
                // No ID <=> new post, simply forward to cancel
                this.cancelHandler()
        }
    };

    cancelHandler = () => {
        this.props.history.goBack();
    };

    canDelete = () => {
        let post = this.props.initialValues
        let canDelete = false
        if (post) {
            canDelete = this.props.userRoles.includes("MODERATOR") || this.props.userRoles.includes("ADMIN") || this.props.userId === post.authorId
        }
        return canDelete
    }

    render() {
        const { handleSubmit, pristine, reset, submitting} = this.props

        return (
            <div style={tr}>
                <form onSubmit={handleSubmit}>
                   {/* <div style={{ display: 'flex', flexDirection: 'row', padding: '0em 2em', textAlign: 'center' }}>
                        <Button style={btn} label="Save" disabled={pristine || submitting} variant="contained" color="primary" aria-label="save" type="submit"> Save </Button>
                        <Button style={btn} label="Reset" disabled={pristine} variant="contained" color="secondary" aria-label="reset" type="button" onClick={reset}> Reset </Button>
                        <Button style={btn} label="Cancel" disabled={submitting} variant="contained" color="secondary" aria-label="cancel" type="button" onClick={(event) => { this.cancelHandler(event) }}> Cancel </Button>
                        <Button style={btn} label="Delete" disabled={submitting || !this.canDelete()} variant="contained" color="secondary" aria-label="delete" type="button" onClick={(event) => { this.deleteHandler(event) }}> Delete post </Button>
                    </div>  */}
                    <table style={table} ><tbody>
                        <tr style={trs} >
                            <td style={label}><label htmlFor="title">Title</label></td>
                            <td><Field name="title" style={input} component="input" type="text" placeholder="A nice title for your post" /></td>
                        </tr>
                        <tr style={trs}>
                            <td style={label}><label htmlFor="slug">Slug</label></td>
                            <td><Field name="slug" style={input} component="input" type="text" placeholder="a-path-to-your-post" /></td>
                        </tr>
                        <tr style={trs}>
                            <td style={label}> <label htmlFor="thumb">Thumbnail image</label> </td>
                            <td> <Field style={input} name="thumb" component="input" type="text" placeholder="A small image (320x240px) for the lists" /> </td>
                        </tr>
                        <tr style={trs}>
                            <td style={label}> <label htmlFor="hero">Hero image</label></td>
                            <td> <Field name="hero" style={input} component="input" type="text" placeholder="A large image (800x200px) for the header" /></td>
                        </tr>
                        <tr>
                            <td style={label}> <label htmlFor="tags">Tags for this post</label></td>
                            <td style={{ border: '0px' }} ><Field name="tags" component={TagSelect} /></td>
                        </tr>
                    </tbody></table>

                    <div style={{ padding: '1em 0.5em' }}>
                        <label htmlFor="desc">Abstract</label>
                        <div >
                            <Field name="desc" component="textarea" style={{ width: '99%', minHeight: '40px', padding: '0.4em' }} placeholder="This is shown as extract" />
                        </div>
                    </div>
                    <div style={{ padding: '1em 0.5em' }} >
                        <label htmlFor="body">Content</label>
                        <div >
                            <Field name="body" component="textarea" style={{ width: '99%', minHeight: '500px', padding: '0.4em' }} placeholder="Express yourself here!" />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', padding: '0em 2em', textAlign: 'center' }}>
                        <Button style={btn} label="Save" disabled={pristine || submitting} variant="contained" color="primary" aria-label="save" type="submit"> Save </Button>
                        <Button style={btn} label="Reset" disabled={pristine} variant="contained" color="secondary" aria-label="reset" type="button" onClick={reset}> Reset </Button>
                        <Button style={btn} label="Cancel" disabled={submitting} variant="contained" color="secondary" aria-label="cancel" type="button" onClick={(event) => { this.cancelHandler(event)}}> Cancel </Button>
                        <Button style={btn} label="Delete" disabled={submitting} variant="contained" color="secondary" aria-label="delete" type="button" onClick={(event) => { this.deleteHandler(event)}}> Delete post </Button>
                    </div>
                </form>
            </div >
        );
    }
}

PostForm = reduxForm({
    // a unique name for the form
    form: 'editPostForm',
    enableReinitialize: true
})(PostForm)


const mapStateToProps = state => {
    return {
        initialValues: state.post.post,
        userId: state.auth.userId,
        userRoles: state.auth.userRoles,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onCreate: () => dispatch(createPost()),
        onOpen: (slug) => dispatch(getPost(slug, true)),
        onSubmit: (post) => dispatch(savePost(post)),
        onDelete: (slug) => dispatch(deletePost(slug)),
    };
};

export default  withErrorHandler(connect(mapStateToProps, mapDispatchToProps)(PostForm), apiServer);
