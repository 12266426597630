import React, { Component } from 'react';
import { connect } from 'react-redux';

import { List } from '@material-ui/core';

import AuxWrapper from '../../hoc/AuxWrapper/AuxWrapper';
import { fetchPosts } from '../../store/actions/posts'
import PostListItem from '../../components/blog/PostListItem';
import PostCard from '../../components/blog/PostCard';
import BookCard from '../../components/blog/BookCard';

// TODO clean this
import classes from '../../components/blog/blog.module.css';

import { Button, Icon } from '@material-ui/core';

class PostList extends Component {

    state = {
        prevId: null
    };

    componentDidMount() {
        // console.log('in PostList.compDidMount, list type:', this.props)
        // if (this.props.match.url === '/all') {
        //     this.props.onDisplay('', this.props.listType);
        // } else if (this.props.match.params.id) {
        //     this.props.onDisplay("tag=" + this.props.match.params.id, this.props.listType);
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.state.prevId) {
        //     this.props.onDisplay("tag=" + this.state.prevId, this.props.listType);
        // } else {
        //     this.props.onDisplay("", this.props.listType);
        // }
    }

    static getDerivedStateFromProps(props, state) {

        let currId = ""
        if (props.match.params.id) {
            currId = props.match.params.id
        }

        if (!state.prevId || currId !== state.prevId) {
            let query = currId === "" ? "" : "tag=" + currId;
            props.onDisplay(query, props.listType);
            return {
                prevId: currId,
            }
        }
        // No state update necessary
        return null;
    }

    postSelectedHandler = (event, slug) => {
        event.stopPropagation();
        event.preventDefault();
        this.props.history.push('/p/' + slug);
    };

    newPostHandler = () => {
        this.props.history.push('/p/edit');
    };

    canCreatePost = () => {
        return this.props.isAuth && this.props.userRoles && (this.props.userRoles.includes("EDITOR") || this.props.userRoles.includes("MODERATOR"));
    }

    getAddBtn = () => {
        let btns = null;
        if (this.canCreatePost()) {
            btns = (
                <div className={classes.SideButtons}>
                    <Button
                        color="primary"
                        aria-label="add"
                        className={classes.button}
                        onClick={this.newPostHandler}
                    >
                        <Icon>add_circle</Icon>
                    </Button>
                </div>
            );
        }
        return btns;
    }

    getPostLayout = (post, type) => {
        let postCard;
        switch (type) {
            case "card":
                postCard = (
                    <PostCard key={post.slug} query={this.props.query}  {...post}
                        clicked={(event) => this.postSelectedHandler(event, post.slug)} />);
                break;
            case "book":
                postCard = (
                    <BookCard key={post.slug}
                        clicked={(event) => this.postSelectedHandler(event, post.slug)}  {...post} />);
                break;
            default:
                postCard = (
                    <PostListItem key={post.slug} post={post}
                        postSelected={(event) => this.postSelectedHandler(event, post.slug)} />);
        }
        return postCard
    }

    render() {
        let postList = <p style={{ textAlign: 'center' }}>Loading...</p>

        if (this.props.posts && this.props.posts.length > 0) {
            postList = this.props.posts.map(post => this.getPostLayout(post, this.props.listType));
        };

        return (
            <AuxWrapper>
                {this.getAddBtn()}
                {this.props.listType === 'book' ? (
                    <div className={classes.Books} key={this.props.match.params.id} >
                        {postList}
                    </div>) : (<List className={classes.Posts} key={this.props.match.params.id} >
                        {postList}
                    </List>)
                }
            </AuxWrapper>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuth: state.oidc.user != null,
        userRoles: state.auth.userRoles,
        posts: state.posts.posts,
        query: state.posts.query
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDisplay: (queryString, listType) => dispatch(fetchPosts(queryString, listType))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostList);
