import React from 'react';

import NavigationItems from './NavigationItems';
import AuxWrapper from '../../hoc/AuxWrapper/AuxWrapper'

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { Drawer, Fab } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

const drawerWidth = 300;

const styles = theme => ({
    drawer: {
        display: 'flex',
        flexFlow: 'column',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        // maxWidth: '260px',
        // display: 'flex',
        // flexFlow: 'column',
        margin: '2em 1em',
        padding: 0,
    },
    hide: {
        display: 'none',
    },
    grow: {
        flexGrow: 1,
    },
});

class SideDrawer extends React.Component {

    state = {
        open: false,
    };

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes, className, isAuth, navItems, privItems } = this.props;
        const { open } = this.state;

        let privMenu = [{ url: '/login', label: 'S\'identifier' }]

        if (this.props.isAuth) {
            privMenu = [ ...privItems, { url: '/logout', label: 'Se déconnecter' }];
        }

        return (
            <AuxWrapper>
                <Fab color="secondary" aria-label="Menu" className={classNames(className)} onClick={this.handleDrawerOpen}>
                    {/* Do not hide when side menu is opened
                        <Fab color="secondary" aria-label="Menu" className={classNames(className, open && classes.hide)} onClick={this.handleDrawerOpen}> */}
                    <MenuIcon />
                </Fab>
                <Drawer className={classNames(classes.drawer)} open={open} onClose={this.handleDrawerClose}>
                    <NavigationItems className={classNames(classes.content)} isAuth={isAuth} navItems={navItems} />
                    <div className={classNames(classes.grow)} />
                    <NavigationItems className={classNames(classes.content)} isAuth={isAuth} navItems={privMenu} />
                    {/* onClose={this.handleDrawerClose}/>: in fact rather not, feels bad upon usage... */}
                </Drawer>
            </AuxWrapper>
        );
    }
}

export default withStyles(styles)(SideDrawer);
